<template>
  <div class="operating-item d-flex justify-content-between" @click="itemClick">
    <div class="name d-flex align-items-center flex-nowrap">
      <Icon class="icon" :name="icon" size="24" />
      <div class="fs-h5" v-text="name"></div>
    </div>
    <div class="info d-flex align-items-center flex-nowrap">
      <!--              <div class="fs-body-16" v-text="item.info"></div>-->
      <slot></slot>
      <Icon class="icon d-none d-md-block" name="edit" size="24" />
    </div>
  </div>
</template>

<script>
import svgEdit from "@/assets/images/icon/edit-gray.svg";

export default {
  name: 'PathOperatingItem',
  data() {
    return {
      imageSrc: {
        svgEdit: svgEdit,
      },
    };
  },
  props: {
    icon: {
      required: true,
    },
    name: {
      required: true,
    },
  },
  components: {
  },
  computed: {
	},
  watch: {
  },
  mounted() {
	},
  methods: {
    itemClick(event) {
      this.$emit('itemClick', event)
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
</style>
