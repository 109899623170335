<template>
  <div class="layout-main bg-white">
    <section class="journey edit-mode">
      <div class="header journey-block">
        <div class="fs-h2 text-center">編輯登山計畫</div>
        <button class="btn common-oval-btn dark fs-body-18">完成</button>
      </div>
      <div class="journey-name journey-block">
        <div class="fs-body-16">路線名稱</div>
        <div class="fs-body-18">玉山主峰</div>
      </div>
      <div class="journey-content journey-block">
        <div class="block-title fs-h4">計畫內容</div>
        <div class="operating-list">
          <path-operating-Item :icon="operatingList.time.src" :name="operatingList.time.name" @itemClick="openDatePicker">
            <common-date-picker ref="commonDatePicker" v-model="trail_start" :open="datePickerOpen" @close="closeFlatpickr"></common-date-picker>
          </path-operating-Item>
          <path-operating-Item :icon="operatingList.days.src" :name="operatingList.days.name" @itemClick="openDaysSelect">
            <common-select ref="daysSelect" :options="templateOptions" :value="selectTempIndex" @update="updateDays"></common-select>
          </path-operating-Item>
          <path-operating-Item :icon="operatingList.people.src" :name="operatingList.people.name">
          </path-operating-Item>
        </div>
      </div>
      <div class="journey-plan journey-block">
        <div class="block-title fs-h4">路線規劃</div>
        <div class="content-list">
          <div class="content-item d-flex justify-content-between">
            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <img class="info-icon" :src="imageSrc.svgDirectionsWalk" alt="行走">
                <div class="info-text fs-body-16">行走</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="directionWalkHr"></span>
                <span>:</span>
                <span v-text="directionWalkMin"></span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <img class="info-icon" :src="imageSrc.svgDistance" alt="距離">
                <div class="info-text fs-body-16">距離</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="distance"></span>
                <span>km</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <img class="info-icon" :src="imageSrc.svgClimbUp" alt="總升">
                <div class="trail-info-text fs-body-16">總升</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="climbUp"></span>
                <span>m</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <img class="info-icon" :src="imageSrc.svgClimbDown" alt="總降">
                <div class="info-text fs-body-16">總降</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="climbDown"></span>
                <span>m</span>
              </div>
            </div>
          </div>
          <div class="content-item d-flex justify-content-center">
            <speed-controller v-model="climbRate"></speed-controller>
          </div>
          <div class="content-item">
            <div class="journey-info row">
              <div class="col-6">
                <div class="box">
                  <path-map ref="map" v-if="shownPathFullPosition.length > 0" :path="shownPathFullPosition" :milestones="shownPathMilestones"></path-map>
                </div>
              </div>
              <div class="col-6">
                <div class="box overflow-auto" v-if="generatedJourney">
                  <journey-viewer :journey="generatedJourney" :show-opt-btn="true" :show-weather="false" :show-start-time-select="true" @optclick="milestoneOptClicked" @start-time-changed="dayStartTimeChanged"></journey-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="journey-order journey-block">
        <div class="block-title fs-h4">服務預訂</div>
      </div>
    </section>
    <b-modal modal-class="rest-time" ref="diagram-modal" title="設定休息時間" size="sm" centered v-model="showRestModal" @ok="milestoneRestTimeModalSaveClicked">
      <select v-model="milestoneRestTime">
        <option v-for="(item, index) in milestoneRestTimeOpts" :key="index" :value="item" v-text="item + '分鐘'"></option>
      </select>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import PathOperatingItem from "@/views/path/PathOperatingItem";
import CommonDatePicker from "@/components/common/CommonDatePicker";
import CommonSelect from "@/components/common/CommonSelect";
import SpeedController from "@/components/common/SpeedController";
import PathMap from "@/components/common/PathMap";
import JourneyViewer from "@/components/common/JourneyViewer";

import svgDate from "@/assets/images/icon/svg/Date.svg";
import svgGroup from "@/assets/images/icon/svg/Group.svg";
import svgEdit from "@/assets/images/icon/edit-gray.svg";
import svgDistance from "@/assets/images/icon/svg/distance.svg";
import svgDirectionsWalk from "@/assets/images/icon/svg/directions_walk.svg";
import svgClimbUp from "@/assets/images/icon/svg/climb-up.svg";
import svgClimbDown from "@/assets/images/icon/svg/climb-down.svg";

export default {
  name: 'PathCreateJourney',
  beforeRouteLeave(to, from, next) {
    this.$store.commit('clearPassedData');
    next();
  },
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      pathDetail: null,
      shownPathMilestones: [],
      shownPathFullPosition: [],
      shownPathFullHeights: [],

      operatingList: {
        time: { name:'出發日期', src: svgDate },
        days: { name:'行程天數', src: svgDate },
        people: { name:'參與人數', src: svgGroup },
      },

      datePickerOpen: false,
      date: moment().format('YYYY-MM-DD'),
      days: '單攻',
      people: 1,

      selectTempIndex: 0,
      trail_start: moment().format('YYYY-MM-DD'),
      pathTemplates: [],
      restAry: [],
      startTimes: [],
      climbRate: 1,
      climbUnit: -1,

      showRestModal: false,
      milestoneRestTimeSettingIndex: -1,
      milestoneRestTime: 15,
      milestoneRestTimeOpts: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 60, 75, 90],

      imageSrc: {
        svgEdit: svgEdit,
        svgDistance: svgDistance,
        svgDirectionsWalk: svgDirectionsWalk,
        svgClimbUp: svgClimbUp,
        svgClimbDown: svgClimbDown,
      },
    };
  },
  props: {
  },
  components: {
    PathOperatingItem,
    CommonDatePicker,
    CommonSelect,
    SpeedController,
    PathMap,
    JourneyViewer,
  },
  computed: {
    directionWalkHr() {
      if(this.pathDetail) {
        return parseInt(this.pathDetail.pathInfo.totalWalkTime/60);
      } else {
        return '';
      }
    },
    directionWalkMin() {
      if(this.pathDetail) {
        return String(parseInt(this.pathDetail.pathInfo.totalWalkTime%60)).padStart(2, '0');
      } else {
        return '';
      }
    },
    distance() {
      if(this.pathDetail) {
        return (this.pathDetail.pathInfo.totalDistance/1000).toFixed(1);
      } else {
        return '';
      }
    },
    climbUp() {
      if(this.pathDetail) {
        return (this.pathDetail.pathInfo.totalUp).toFixed(0);
      } else {
        return '';
      }
    },
    climbDown() {
      if(this.pathDetail) {
        return (this.pathDetail.pathInfo.totalDown).toFixed(0);
      } else {
        return '';
      }
    },
    selectedTemp: function() {
      if (this.selectTempIndex === -1) {
        return null;
      }
      return this.pathTemplates[this.selectTempIndex];
    },
    generatedJourney: function() {
      const res = {... this.selectedTemp};

      // 起始日期與結束日期
      let cDate = moment(this.trail_start);
      res.startDate = cDate.format('YYYY-MM-DD');
      cDate.add(res.durationDays - 1, 'days');
      res.endDate = cDate.format('YYYY-MM-DD');

      //中間路途
      const newJourney = [];

      let stepTime = moment(`${res.startDate} ${this.startTimes[0]}`);
      let dayCount = 0;
      if(this.selectedTemp) {
        for (let i=0;i<this.selectedTemp.journey.length;i++) {
          const newJ = {... this.selectedTemp.journey[i]};
          newJ.startTimestamp = stepTime.valueOf();
          newJ.startTime = stepTime.format('YYYY-MM-DD HH:mm');

          if (res.journeySplitIndexs.indexOf(i) !== -1) {
            stepTime = moment(`${res.startDate} ${this.startTimes[dayCount+1]}`).add(dayCount, 'days');
            dayCount = dayCount + 1;
          } else {
            if (newJ.type === 'route') {
              const costMinutes = (newJ.direction === 0)?newJ.detail.consuming:newJ.detail.returnConsuming;
              const lastCostMinutes = costMinutes * this.climbRate
              stepTime.add(lastCostMinutes, 'minutes');
            } else if (newJ.type === 'milestone') {
              const costMinutes = this.restAry[i/2];
              stepTime.add(costMinutes, 'minutes');
            }
          }
          newJ.endTimestamp = stepTime.valueOf();
          newJ.endTime = stepTime.format('YYYY-MM-DD HH:mm');

          newJ.duration = (newJ.endTimestamp - newJ.startTimestamp) * this.climbRate / (60 * 1000);

          newJourney.push(newJ);
        }
      }
      res.journey = newJourney;
      return res;
    },
    templateOptions: function() {
      return this.pathTemplates.map((template, index) => ({
        name: template.name,
        val: index,
      }));
    },
	},
  watch: {
    selectTempIndex: function() {
      this.reselectJourney();
    },
    startTimes: {
      handler: function() {
      },
      deep: true,
    }
  },
  async mounted() {
    // console.log(this.$route.params.id)
    await this.refresh();
  },
  methods: {
    calcClimb(unitStep = -1) {
      this.totalClimbUp = 0;
      this.totalClimbDown = 0;
      let lastHeight = 0;
      let inited = false;
      for (const j of this.selectedTemp.journey) {
        if (j.type === 'route' && unitStep > 0) {
          let i = 0;
          let step = unitStep;
          if (j.direction === 1) {
            //反向
            i = j.detail.heights.length - 1;
            step = -1 * unitStep;
          }
          for (;i<j.detail.heights.length && i>=0; i += step) {
            const h = j.detail.heights[i];
            if (!inited) {
              inited = true;
            } else {
              const dis = h - lastHeight;
              if (dis > 0) {
                this.totalClimbUp += dis;
              } else {
                this.totalClimbDown -= dis;
              }
            }
            lastHeight = h;
          }
        } else if (j.type === 'milestone') {
          const h = j.detail.height;
          if (!inited) {
            inited = true;
          } else {
            const dis = h - lastHeight;
            if (dis > 0) {
              this.totalClimbUp += dis;
            } else {
              this.totalClimbDown -= dis;
            }
          }
          lastHeight = h;
        }
      }
    },
    async refresh() {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        const _this = this;
        const serial = _this.$route.params.id;
        const dayTemplatesRequest = await _this.$store.dispatch('api/readPathDayTemplatesPromise', serial);
        let templates = [];
        for (const dt of dayTemplatesRequest) {
          const templateDetail = await _this.$store.dispatch('api/readJourneyDetailByTemplatePromise', dt.serial);
          templateDetail.tempId = dt.serial;
          templates.push(templateDetail);
        }
        templates = templates.sort(function(a, b) {
          return a.durationDays - b.durationDays;
        });
        _this.pathTemplates.push(...templates);

        if(_this.$store.getters.passData) {
          _this.pathDetail = await _this.$store.getters.passData.pathDetail;
          _this.shownPathMilestones = await _this.$store.getters.passData.shownPathMilestones;
          _this.shownPathFullPosition = await _this.$store.getters.passData.shownPathFullPosition;
        } else {
          _this.pathDetail = await _this.$store.dispatch('api/getJourneyDetailByPathPromise', serial);
          _this.buildFullPathAndHeights(_this.pathDetail.journey);
          _this.buildPathMilestones(_this.pathDetail.journey);
        }

        _this.reselectJourney();
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    buildFullPathAndHeights(journeyItems) {
      // const _this = this;
      this.shownPathFullPosition.splice(0, this.shownPathFullPosition.length);
      this.shownPathFullHeights.splice(0, this.shownPathFullHeights.length);

      journeyItems.forEach((item) => {
        if (item.type === 'milestone') {
          this.shownPathFullPosition.push({
            lat: item.detail.lat,
            lng: item.detail.lng,
          });
        } else if (item.type === 'route') {
          const detail = item.detail;
          const routepts = detail.routepts;
          const heights = detail.heights;

          if (item.direction === 0) {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights);
          } else {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.reverse().map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights.reverse());
          }
        }
      });
    },
    buildPathMilestones(journeyItems) {
      this.shownPathMilestones.splice(0, this.shownPathMilestones.length);

      for (const item of journeyItems) {
        if (item.type === 'milestone') {
          const found = this.shownPathMilestones.filter((m) => {
            return m.serial === item.detail.serial;
          });
          if (found.length === 0) {
            this.shownPathMilestones.push(item.detail);
          }
        }
      }
    },
    reselectJourney: function() {
      this.restAry.splice(0, this.restAry.length);
      this.startTimes.splice(0, this.startTimes.length);

      for (let i=0;i<this.selectedTemp.journey.length;i++) {
        const j = this.selectedTemp.journey[i];
        if (j.type === 'milestone') {
          this.restAry.push(0);
          if (i === 0 || this.selectedTemp.journeySplitIndexs.indexOf(i) !== -1) {
            this.startTimes.push(moment(j.endTimestamp).format('HH:mm'));
          }
        }
      }

      this.updateJourney();
    },
    updateJourney: function() {
      this.buildFullPathAndHeights(this.selectedTemp.journey);
      this.buildPathMilestones(this.selectedTemp.journey);
      this.calcClimb(this.climbUnit);
    },
    milestoneOptClicked(index) {
      this.milestoneRestTimeSettingIndex = index/2;
      this.milestoneRestTime = this.restAry[this.milestoneRestTimeSettingIndex];
      this.showRestModal = true;
    },
    milestoneRestTimeModalSaveClicked() {
      const index = this.milestoneRestTimeSettingIndex;
      this.$set(this.restAry, index, this.milestoneRestTime);
    },
    dayStartTimeChanged(data) {
      this.$set(this.startTimes, data.index, data.startTime);
    },
    showMsgBox(msgTxt) {
      this.$bvModal.msgBoxOk(msgTxt, {
        okVariant: 'success',
        centered: true
      })
      .then(value => {
        console.log(value)
      })
      .catch(err => {
        console.log(err)
      })
    },
    openDatePicker() {
      this.$refs.commonDatePicker.openFlatpickr()
    },
    closeFlatpickr() {
      this.datePickerOpen = false;
    },
    openDaysSelect(event) {
      console.log(event)
    },
    updateDays(val) {
      console.log(val)
      this.selectTempIndex = val;
    },
  }
}
</script>

<style lang="scss">
  @import "src/assets/scss/journey";
</style>
