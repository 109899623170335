<template>
  <div class="speed-controller d-flex align-items-center flex-nowrap flex-shrink-0">
    <input type="number" class="d-none" ref="inputRef" v-bind:value="speedRate"/>
    <button class="btn" @click="decrement()" v-if="!hideBtns">
      <img :src="imageSrc.svgArrowLeftWhite" alt="share btn">
    </button>
    <div class="rate-panel d-flex flex-column align-items-center">
      <div class="rate-txt fs-h5" v-text="rateTxt"></div>
      <div class="rate-comment fs-small-11" v-text="rateComment"></div>
    </div>
    <button class="btn" @click="increment" v-if="!hideBtns">
      <img :src="imageSrc.svgArrowRightWhite" alt="share btn">
    </button>
  </div>
</template>

<script>
import svgArrowLeftWhite from "@/assets/images/icon/arrow-left-white.svg"
import svgArrowRightWhite from "@/assets/images/icon/arrow-right-white.svg"

export default {
  name: 'SpeedController',
  data() {
    return {
      speedRate: this.rate,

      imageSrc: {
        svgArrowLeftWhite: svgArrowLeftWhite,
        svgArrowRightWhite: svgArrowRightWhite,
      },
    }
  },
  model: {
    prop: 'rate',
    event: 'change'
  },
  props: {
    rate: {
      required: false,
      type: Number,
      default: 1,
    },
    minRate: {
      required: false,
      type: Number,
      default: 0.5,
    },
    maxRate: {
      required: false,
      type: Number,
      default: 2,
    },
    hideBtns: {
      require: false,
      type: Boolean,
      default: false,
    }
  },
  computed: {
    rateTxt() {
      return 'x ' + this.speedRate + ' (標準)';
    },
    rateComment() {
      return '行走速率 ( ' + this.minRate + ' - ' + this.maxRate + ' )';
    },
  },
  methods: {
    increment() {
      if(this.speedRate >=  this.maxRate) {
        this.speedRate = this.maxRate
      } else {
        this.speedRate += 0.1;
        this.speedRate = Number(this.speedRate.toFixed(1))
      }
      this.$emit('change', this.speedRate)
      console.log(this.rate)
    },
    decrement() {
      if(this.speedRate <= this.minRate) {
        this.speedRate = this.minRate
      } else {
        this.speedRate -= 0.1;
        this.speedRate = Number(this.speedRate.toFixed(1))
      }
      this.$emit('change', this.speedRate)
      console.log(this.rate)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .speed-controller {
    .rate-txt {
      margin-bottom: 8px;
    }
    .rate-comment {
      color: $color-bk-tp-25;
    }
    .rate-panel {
      padding: 0 8px;
    }
  }
</style>
